import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import { getParamsFromQuery } from 'services/queryString'
import styles from './Category.module.scss'
import IconCaretDown from 'assets/icons/IconCaretDown'
import IconCaretUp from 'assets/icons/IconCaretUp'

let handleDataLayerFunction = null

const Category = ({ category, title, pageType, id=null }) => {
  const { lang } = useTranslation('translations')
  const [dropdownStatus, setDropdownStatus] = useState(true)
  const router = useRouter()
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    setDropdownStatus(isMobileOnly ? false : true)
  }, [])

  const listView =
    category &&
    category.map((item, index) => (
      <li id = {`link${index}`} key={index} className={styles.py1} onClick={() => handleDataLayer(item)}>
        <a href={`${item.url}`} locale={lang} className={styles.fontMedium} >
          {item.title}
        </a>
      </li>
    ))

  const handleDataLayer = async(item) => {
    if (handleDataLayerFunction === null) {
      const { handleDataLayerMethodForCategory } = await import(/* webpackChunkName: "handleDataLayer" */ '../../SEOHeader/Methods/onClickMethods')
      handleDataLayerFunction = handleDataLayerMethodForCategory 
    }
    handleDataLayerFunction( item, isDesktop, routerParams, lang, pageType)
  }

  return (
    <div className={`${styles.colLg3}`}>
      <div
        id = {id}
        className={styles.title}
        onClick={() => {
          if (isMobileOnly) {
            setDropdownStatus(!dropdownStatus)
          }
        }}
      >
        <div className={styles.head}>{title}</div>
        <div className={`${styles.arrow}`}>
          {dropdownStatus ? (
            <IconCaretUp width='24' height='24' strokeColor='currentcolor' />
          ) : (
            <IconCaretDown width='24' height='24' strokeColor='currentcolor' fillColor='none' />
          )}
        </div>
      </div>
      {dropdownStatus && <ul id = {`${id}Links`} className={`${styles.listInline}`}>{listView}</ul>}
    </div>
  )
}

export default Category
