import React from 'react'

const IconCaretUp = ({ width, height, strokeColor, iconTitle }) =>
 (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='title'
    >
      <title>{iconTitle}</title>
      <path
        d='M3.5 9.5L8 5L12.5 9.5'
        stroke={strokeColor || '#4A4C4F'}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );

export default IconCaretUp
